import { useState, useEffect } from 'react';

import type { CiamIdentity } from '@hubcms/domain-ciam';
import { log } from '@hubcms/utils-monitoring';

export const useCiamData = (clientId: string) => {
  const [ciamUser, setCiamUser] = useState<CiamIdentity | null>(null);
  const [authorizationUrl, setAuthorizationUrl] = useState<string | null>(null);
  const [alreadyLoginUrl, setAlreadyLoginUrl] = useState<string | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getAccessToken = () => (typeof window !== 'undefined' ? window.CIAM.getAccessToken(clientId) : null);
  const sendEmailVerification = () =>
    typeof window !== 'undefined' ? window.CIAM.sendEmailVerification(clientId) : Promise.resolve(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (!window.CIAM) {
        log('CIAM not available');
        setIsLoading(false);
        return;
      }

      setIsAuthenticated(window.CIAM.isAuthenticated(clientId));

      const getLoginUrlArgs = (connection: 'mh-google-social' | undefined = undefined) => ({
        clientId,
        options: {
          returnTo: window.location.href,
          ...(connection ? { connection } : {}),
        },
        identityLevel: '',
        tracking: {},
      });

      try {
        const userInfoResult = await window.CIAM.getUserInfo(clientId);
        setCiamUser(userInfoResult);

        const [authorizationUrlResult, alreadyLoginUrlResult] = await Promise.all([
          window.CIAM.getLoginUrl(getLoginUrlArgs('mh-google-social')),
          window.CIAM.getLoginUrl(getLoginUrlArgs()),
        ]);

        setAuthorizationUrl(authorizationUrlResult);
        setAlreadyLoginUrl(alreadyLoginUrlResult);
      } catch (e) {
        log('Failed to get user info from CIAM', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [clientId]);

  return { ciamUser, isAuthenticated, isLoading, getAccessToken, sendEmailVerification, authorizationUrl, alreadyLoginUrl };
};
