import type { UserRegioSelection } from '../domain/user-regio-selection';

import { getSavedMySectionSelection } from './mySectionCookies';

export const getUserRegioSelection = (): UserRegioSelection | null => {
  const mySectionSelectionEntries = getSavedMySectionSelection();

  if (!mySectionSelectionEntries || mySectionSelectionEntries.length === 0) {
    return null;
  }

  const citySelection = mySectionSelectionEntries.find(selection => selection.type === 'city');
  if (!citySelection) {
    return null;
  }

  if (citySelection.communityIdentifier && citySelection.city) {
    return { communityIdentifier: citySelection.communityIdentifier, city: citySelection.city };
  }

  return null;
};
